import get from 'lodash/get'
import React from 'react'
import Disqus from 'disqus-react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'

// eslint-disable-next-line
import SiteInformation from '../fragments/SiteInformationFragment'
// eslint-disable-next-line
import BlogPostDetail from '../fragments/BlogPostDetailFragment'

import {
  ScrollToTopOfPage,
  ContentfulSitePost,
  SideBar,
  NewsletterSignUpForm,
} from 'blog-components'

const messages = {
  newslettersignupform: {
    title: 'Newsletter Signup',
    lead: 'Like what you just read? Signup to our newsletter for more!',
    submit_label: 'Sign Up',
    name_label: 'Name',
    name_placeholder: 'Your name...',
    email_label: 'Email',
    email_placeholder: 'Your email address...',
    success: 'Thanks for signing up!',
  },
}

class BlogPostContentfulTemplate extends ScrollToTopOfPage {
  
  render() {
    const site = get(this.props, 'data.site')

    const relatedPosts = get(this.props, 'data.post.relatedPosts')

    const title = get(this.props, 'data.post.title')
    const authors = get(this.props, 'data.post.authors')
    const description = get(
      this,
      'props.data.post.description.childMarkdownRemark.rawMarkdownBody'
    )
    const slug = get(this.props, 'data.post.slug')
    const publishDate = get(this.props, 'data.post.publishDate')
    const createdAt = get(this.props, 'data.post.createdAt')
    const updatedAt = get(this.props, 'data.post.updatedAt')
    const shareImage = get(this.props, 'data.post.shareImage.fixed_1200x630.src')
    const htmlAst = get(
      this,
      'props.data.post.body.childMarkdownRemark.htmlAst'
    )
    const categories = get(this.props, 'data.post.categories')

    const disqusConfig = {
      url: site.siteMetadata.siteUrl + slug,
      identifier: slug,
      title: title,
    }

    return (
      <Layout>
        <div className="container fadein-3 blog-post" data-emergence="visible">
          <div className="row">
            <div className="articles col-lg-10">
              <ContentfulSitePost
                slug={slug}
                title={title}
                authors={authors}
                publishDate={publishDate}
                createdAt={createdAt}
                updatedAt={updatedAt}
                shareImage={shareImage}
                description={description}
                categories={categories}
                htmlAst={htmlAst}
                site={site}
              />
            </div>
            <div className="sidebar col-lg-2">
              <SideBar relatedPosts={relatedPosts} />
            </div>
          </div>

          <div className="row">
            <div className="offset-lg-1 col-lg-10">
              <NewsletterSignUpForm
                title={messages.newslettersignupform.title}
                lead={messages.newslettersignupform.lead}
                submit_label={messages.newslettersignupform.submit_label}
                name_label={messages.newslettersignupform.name_label}
                name_placeholder={
                  messages.newslettersignupform.name_placeholder
                }
                email_label={messages.newslettersignupform.email_label}
                email_placeholder={
                  messages.newslettersignupform.email_placeholder
                }
                success={messages.newslettersignupform.success}
              />
            </div>
          </div>

          <div className="row">
            <div className="offset-lg-1 col-lg-10">
              <Disqus.DiscussionEmbed
                shortname={site.siteMetadata.disqusShortname}
                config={disqusConfig}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostContentfulTemplate

export const pageQuery = graphql`
  query ContentfulBlogPostByPath($path: String!) {
    site {
      ...SiteInformation
    }
    post: contentfulBlogPost(slug: { eq: $path }) {
      ...BlogPostDetail
    }
  }
`
