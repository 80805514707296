import { graphql } from 'gatsby'

// eslint-disable-next-line
import ShareImagesFragment from './ShareImagesFragment'

export const BlogPostDetailFragment = graphql`
  fragment BlogPostDetail on ContentfulBlogPost {
    title
    slug
    description {
      childMarkdownRemark {
        rawMarkdownBody
      }
    }
    publishDate(formatString: "DD/MM/YYYY")
    createdAt(formatString: "DD/MM/YYYY")
    updatedAt(formatString: "DD/MM/YYYY")
    shareImage {
      ...ShareImages
    }
    relatedPosts {
      slug
      title
      visible
    }
    authors {
      slug
      name
    }
    categories {
      title
      slug
    }
    body {
      childMarkdownRemark {
        html
        htmlAst
      }
    }
  }
`
